import React from 'react';
import { Button, Text } from 'wix-ui-tpa/cssVars';
import { useTranslate } from '../../../hooks/use-translate';
import { classes, st } from './app.st.css';
import { ThankYouWidgetProps } from '../../types';
import PreviewBlocker from '~commons/components/preview-blocker/preview-blocker';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { unreachable } from '~/ts-utils';

export const App: React.FC<ThankYouWidgetProps> = ({
  ctaState,
  view,
  ctaLink,
  isEditor,
  isPreview,
}) => {
  const { isMobile } = useEnvironment();
  const t = useTranslate();
  return (
    <div className={st(classes.root, { forcedHover: ctaState === 'Hover' })}>
      {(isPreview || isEditor) && <PreviewBlocker isMobile={isMobile} />}
      {(() => {
        switch (view) {
          case 'DEFAULT':
            return (
              <>
                <Text className={classes.title}>{t('thank-you.title')}</Text>
                <Text className={classes.body}>{t('thank-you.body')}</Text>
                <Button className={classes.cta} as="a" href={ctaLink}>
                  {t('thank-you.cta')}
                </Button>
              </>
            );
          case 'REVIEW_ALREADY_EXISTS':
            return (
              <>
                <Text className={classes.title}>{t('review-exists.title')}</Text>
                <Text className={classes.body}>{t('review-exists.body')}</Text>
                <Button className={classes.cta} as="a" href={ctaLink}>
                  {t('review-exists.cta')}
                </Button>
              </>
            );
          case 'ENTITY_NOT_FOUND':
            return (
              <>
                <Text className={classes.title}>{t('entity-not-found.title')}</Text>
                <Text className={classes.body}>{t('entity-not-found.body')}</Text>
                <Button className={classes.cta} as="a" href={ctaLink}>
                  {t('entity-not-found.cta')}
                </Button>
              </>
            );
          case 'REVIEW_PENDING':
            return (
              <>
                <Text className={classes.title}>{t('review-pending.title')}</Text>
                <Text className={classes.body}>{t('review-pending.body')}</Text>
                <Button className={classes.cta} as="a" href={ctaLink}>
                  {t('review-pending.cta')}
                </Button>
              </>
            );
          default:
            throw unreachable(view);
        }
      })()}
    </div>
  );
};
